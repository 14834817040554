.categoria__interna{
	.paginacao{
		justify-content: center;
	}
	.col-lg-3{
		margin-bottom: 40px;
	}
}
.categoria__interna__desc{
	text-align: justify;
	margin-bottom: 30px;
}
.filtros__categorias{
	display: flex;
	justify-content: center;
	margin-bottom: 50px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
	}

	.segura__filtro{
		width: 50%;
		position: relative;

		@include media-breakpoint-up (lg) {
			&:after{
				width: 2px;
				height: 30px;
				background-color: #c7c7c7;
				position: absolute;
				content: '';
				top: calc(50% - 15px);
				right: -1px;
			}
		}

		.v-select-output{
			display: flex;
			justify-content: center;
			border-radius: 0px;
			border: none;
			border-top: 1px solid #c7c7c7;
			border-bottom: 1px solid #c7c7c7;
			font-family: poppinsbold;
			color: #000;
			padding-top: 20px;
			padding-bottom: 20px;
			height: auto;

			&:before{
				float: none;
				order: 2;
				border-left-width: 0.4em;
				border-right-width: 0.4em;
				border-top-width: 0.4em;
			}
		}
	}
}
.contato__check{
	label{
		font-family: 'poppinsregular' !important;

	a{
		text-decoration: underline !important;
	}
	}
}
.internas{
	.perguntas{
		padding-top: 0px !important;
	}
}
.box__filtro__ordenacao{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;

	& > * {
		width: 50%;
	}

	@include media-breakpoint-down(sm){
		flex-direction: column;
		align-items: flex-start;
	}

	.filtro{
		position: relative;
		width: 100%;

		@include media-breakpoint-down(sm){
			margin-right: 0;
		}
	}

}
.filtro__esquerda{
	.btn__filtro,
	.dropdown{
		max-width: 470px;
	}
}
.filtro__direita{
	display: grid;
	grid-template-columns: calc(40% - 10px) calc(60% - 10px);
	column-gap: 20px;
}
.btn__filtro,
.btn__ordem{
	background-color: #000;
	width: 100%;
	color: #fff;
	font-family: poppinsbold;
	display: flex;
	justify-content: space-between;
	padding: 18px 23px;
	align-items: center;
	border-radius: 0px;
	font-size: 14px;
	line-height: 14px;

	&:after{
		border: none;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		content: '\f0d8';
		transition: all 0.3s linear;
	}

	&:hover{
		color: #fff;
	}

	&[aria-expanded="true"] {
		&:after{
			transform: rotate(180deg);
		}
	}
}
.box__mid{
	.categoria__box{
		margin-bottom: 20px;
	}
}

.filtro__btn__segura{
	text-align: center;
}
.categoria__header{
	background-color: #000;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	padding: 16px 20px 14px;
}
.titulo__categoria{
	font-family: poppinsbold;
	font-size: 15px;
	line-height: 18px;
	color: #000;
	margin-bottom: 14px;
}


.dropdown-menu-filtro,
.dropdown-menu{
	ul{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;

		li{
			position: relative;
		}

		.categoria__item__interna{
			display: flex;
			justify-content:space-between;
			padding: 15px 20px;
			align-items: center;
			font-size: 13px;
			line-height: 13px;
			position: relative;

			&:hover{
				font-family: poppinsbold;
				background-color: #e0e0e0;
			}
		}

		// li{
		// 	&:last-child{
		// 		a{
		// 			border-radius: 0px 0px 15px 15px;
		// 		}
		// 	}
		// }

		.segura__categoria__controls{
			position: relative;
			.categoria__item__interna{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 20px;
				padding-right: 40px;


				&:hover{
					font-family: poppinsbold;
					background-color: #e0e0e0;
				}
			}
			button{
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

.categoria__lista{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;

	@include media-breakpoint-down(md){
		margin-bottom: 10px;
	}
}
.categoria__quant{
	margin-left: 5px;
}
.categoria__mais{
	border: none;
	background-color: transparent;
	padding: 0px;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 15px;
	color: #000;
	margin-top: 5px;
	display: inline-block;

	@include media-breakpoint-down(md){
		margin-bottom: 30px;
	}

	span{
		font-size: 12px;
		line-height: 14px;
	}

	&:focus{
		outline: none;
	}
}
.categoria__segura__radio{
	margin-bottom: 0px;
	.categoria__radio{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .categoria__label{

				&:before{
					border-color: #c61623;
				}
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.categoria__label{
		padding-left: 20px;
		position: relative;
		font-family: poppinslight;
		font-size: 14px;
		line-height: 18px;
		color: #000;
		transition: all 0.3s linear;
		cursor: pointer;

		&:hover{

			&:before{
				border-color: #000;
				background-color: #fff;
			}
		}

		&:before{
			content: '';
			position: absolute;
			top: 40%;
			transform: translateY(-50%);
			left: 0px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid #c61623;
			transition: all 0.3s linear;
			margin-top: 2px;
		}

		&:after{
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #c61623;
			top: 40%;
			left: 3px;
			transform: translateY(-1px);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.categoria__interna{
	.col-xl-9{
		margin-bottom: 10px;

		.titulo__interna{
			margin-bottom: 10px;
		}

		.col-lg-6{
			margin-bottom: 20px;
		}
	}
	.paginacao{
		padding-top: 30px;
	}
}
.categoria__mais{
	&[aria-expanded="true"] {
		&.categ__mais{
			display: none;
		}
	}
	&[aria-expanded="false"] {
		&.categ__menos{
			display: none;
		}
	}
}
.subcategoria__lista{
	padding-left: 15px;
	list-style: none;
	margin-bottom: 5px;

	.subcategoria__item{
		margin-bottom: 5px;

		.categoria__item__interna{
			font-size: 14px;
			line-height: 18px;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
		}
	}
}
.segura__categoria__controls{
	display: flex;
	align-items: center;

	button{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		background-color: #000;
		color: #fff;
		border-radius: 50%;
		border: none;
		min-width: 18px;
		min-height: 18px;
		width: 18px;
		height: 18px;
		padding: 0px;
		font-size: 8px;
		line-height: 8px;
		margin-left: 5px;
		margin-bottom: 2px;
		transition: all 0.3s linear;
		padding-top: 1px;

		&:focus{
			outline: none;
		}
		&[aria-expanded="true"] {
			transform: rotate(90deg);
		}
	}
}
.box__filtro__ordenacao{
	.dropdown{
		&.show{
			.btn__filtro,
			.btn__ordem{
				position: relative;
				z-index: 1050;
				background: #c61623;
				color: #fff;
			}
		}

		.dropdown-menu{
			z-index: 1000;
			border-radius: 0;
			box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			-webkit-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.75);
			width: 100%;
			left: 0px !important;
			transform: none !important;
			top: calc(100% - 20px) !important;
			background-color: #ededed;
			padding-top: 30px;
			border-radius: 0px;

			.drop-content{
				display: flex;
				padding: 20px;

				.categoria__box{
					width: 100%;
				}

				@include media-breakpoint-down(md){
					flex-direction: column;
					align-items: flex-start;
				}
			}


		}
		.box__filtro__ordenacao{
			position: relative;
		}

		.btn__filtrar{
			width: 160px;
			height: 40px;
			border-radius: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #000;
			font-size: 13px;
			font-weight: bold;
			font-family: poppinsbold;
			text-transform: uppercase;
			transition: background .4s linear;
			margin-top: 30px;
			margin-left: auto;
			margin-right: auto;

			&:hover{
				background: map-get($cores , cor-secundaria);
			}
		}
	}
}
.row__categ{
	.col-lg-4{
		margin-bottom: 30px;
	}
}
