.endereco__item{
	margin-bottom: 30px;
	font-family: 'poppinsregular';
	font-size: 14px;
	line-height: 24px;
	color: #000000;
}
.endereco__titulo{
	margin-bottom: 20px;
	font-size: 15px;
	line-height: 18px;
	font-family: 'poppinsbold';
	color: #000000;
}
.endereco__interna{
	max-width: 960px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.endereco__btns{
	display: flex;
	flex-wrap: wrap;
	padding-top: 10px;

	.conteudo__btn{
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
.v-select{
    &.show{
        z-index: 600;
    }
}
.endereco__form,
.custom__form{
	.v-select-output{
		display: block;
		width: 100%;
		height: auto;
		padding: 16px 24px 18px 24px;
		border: none;
		background-color: #fff;
		font-size: 13px;
		line-height: 16px;
		color: #666;
		font-family: 'poppinsregular';
		resize: none;
		border: 1px solid #d1d1d1;
		border-radius: 0px;

		&:focus{
			box-shadow: none;
		}

		&:before{
			margin-top: 4px;
		}
	}
	.v-options{
		font-size: 13px;
		line-height: 15px;
		margin: none;
		max-height: calc(36px * 4);
		border-radius: 0px;
        z-index: 500;
		box-shadow: 0px 0px 5px rgba(#000000, 0.3);
		background-color: #e4e4e4;

		li{
			color: #000000;
			font-family: 'poppinsregular';
			padding: 15px 22px;

			&.selected{
				background-color: #d1d1d1;
				color: #000000;
			}
		}
	}
}
.endereco__listagem{
	padding-bottom: 30px;
}
.endereco__listagem__detalhe{
	padding-bottom: 20px;
	border-bottom: 5px solid #EEEEEE;
	margin-bottom: 20px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.form__item{
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 18px;
}
.endereco__form{
	width: 100%;
	margin-right: auto;
	margin-left: auto;
    padding-top: 30px;

    .form-group{
        margin-bottom: 8px;
    }
}
.endereco__detalhe__btn{
	padding-top: 20px;
	display: flex;
	justify-content: flex-end;

	.conteudo__btn{
		margin: 0px 5px;
	}
}
.interna__endereco{
	max-width: 930px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

.segura__endereco__item{
	input{
		position: fixed;
		left: -100vw;

		&:checked{
			~ label{

				// &:before{
				// 	border-color: #000000;
				// 	background-color: #fff;
				// }
				&:after{
					opacity: 1;
				}

				&:before{
					border-color: #c61623;
				}
			}
		}
	}
	label{
		position: relative;
		margin-bottom: 0px;
		padding-left: 25px;
		display: block;
		margin-left: 10px;
		cursor: pointer;
		color: #000000;
		font-family: 'poppinsregular';
		font-size: 14px;
		line-height: 24px;

		&:before{
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			border: 2px solid #000;
			transition: all 0.3s linear;
			background-color: transparent;
			z-index: 10;
		}

		&:after{
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #c61623;
			top: 5px;
			left: 5px;
			transition: all 0.3s linear;
			opacity: 0;
			z-index: 5;
		}
	}
}
.endereco__listagem{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 20px;
	max-width: 600px;
	width: 100%;
}
.endereco__adicionar{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	.conteudo__btn{
		margin: 10px;
	}
}
