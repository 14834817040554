.topo ul{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.carrinho__link__topo{
	&:hover{
		text-decoration: none;
	}
}
.categoria__link{
	display: block;
	padding: 16px 15px;
	background-color: #ededed;
	color: #000;
	font-family: poppinsbold;
}
.segura__sociais__topo{
	display: flex;
	align-items: center;

	.sociais__span__topo{
		margin-right: 5px;
		font-size: 13px;
		line-height: 15px;
	}
}
.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.main__menu{
	font-size: 13px;
	line-height: 15px;
	font-family: poppinsbold;
	a{
		padding: 10px 13px;
		display: block;

		&:hover{
			text-decoration: none;
			color: #c61623;
		}
	}
}
.contato__sociais{
	display: flex;
	align-items: center;
	justify-content:center;

	a{
		width: 24px;
		height: 24px;
		min-width: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #2b3f38;
		color: #2b3f38;
		font-size: 14px;
		line-height: 14px;
		border-radius: 50%;
		margin: 0px 2px;
		transition: all 0.3s linear;

		&:hover{
			text-decoration: none;
			background-color: #c61623;
			color: #fff;
			border-color: #c61623;
		}
	}
}
.segura__contato__topo{
	font-family: poppinsregular;
	font-size: 13px;
	line-height: 15px;
	color: #000;
}
.contato__tel,
.contato__email{
	display: flex;
	align-items: center;


	.icon__mask{
		color: #000;
		margin-right: 5px;
	}
}
.carrinho__icone,
.login__icone{
	width: 40px;
	height: 40px;
	min-width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: #c61623;
	color: #fff;
	margin-right: 5px;
	position: relative;
}
.carrinho__texto{
	font-family: poppinsregular;
	font-size: 14px;
	line-height: 16px;
	color: #000;

	.numero__carrinho__topo{
		font-family: poppinsregular;
		color: #000;
	}
}
.segura__login__topo{

	@include media-breakpoint-up (lg) {
		margin-right: 30px;
	}
	@include media-breakpoint-down (md) {
		margin-top: 0px;
	}


    .login__toggler{
        display: flex;
        align-items: center;
		max-width: 160px;
		color: #000;
		font-size: 14px;
		line-height: 16px;

		.login__texto{
			span{
				font-family: poppinsbold;
			}
		}

        &:hover{
            text-decoration: none;
			.login__icone{
				&:before{
					opacity: 1;
				}
			}
        }
    }
    .dropdown{
        display: none;

		&:hover{
            text-decoration: none;
			.login__icone{
				&:before{
					opacity: 1;
				}
			}
        }
    }

    &.logado{
        .login__toggler{
            display: none;
        }
        .dropdown{
            display: block;
        }
    }
}
.logado__toggler,
.login__toggler{
	display: flex;
	align-items: center;
}
.logado__toggler{
	border: none;
	padding: 0px;
	background-color: transparent;

	.login__texto{
		text-align: left;
		font-size: 14px;
		line-height: 16px;
	}
}
.logado__nome{
    padding: 24px 20px 22px 20px;
	background: #c61623;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
	text-align: left;
	font-family: poppinsbold;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 10px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 16px 10px 16px 10px;
        border-bottom: 1px solid rgba(#000000, 0.1);
        font-size: 14px;
        line-height: 14px;
        font-family: 'poppinsregular';
        color: #000000;

        &:hover{
            color: #c61623;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: #fff;
    align-items: center;
	border-radius: 20px;
	border: 1px solid #000;
	padding: 0px;
	padding-right: 8px;

    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #c61623;
        color: #fff;
        min-width: 30px;
        width: 30px;
        height: 30px;
        font-size: 18px;
        line-height: 18px;
        border: none;
		border-radius: 20px;
		position: relative;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'poppinsregular';
        font-size: 13px;
        line-height: 15px;
        padding: 12px 20px 12px 20px;
        border: none;
        background-color: transparent;
        color: #000;
        border: none;
        border-radius: 20px;
		height: 40px;

        &::placeholder{
            color: #000;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}

.encerrar__item{
    padding: 18px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 44px;
        background-color: transparent;
		border: 1px solid #000;
		color: #000;
        font-family:'poppinsbold';
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        transition: all 0.3s linear;
		position: relative;
		border-radius: 0px;


		span{
			position: relative;
			z-index: 200;
		}

		@include media-breakpoint-down(md) {
            height: 28px;
			border-radius: 14px;
        }

        &:hover{
            color: #fff;
            text-decoration: none;
			background-color: #000;
        }
    }
}
.link__categoria__especial{
	&[aria-expanded="true"]{
		.setalt{
			transform: rotate(-90deg);
		}
	}
}
@include media-breakpoint-up (lg) {
	.link__categoria__especial{
		background-color: #fff;
		color: #000;
		position: relative;
		font-family: poppinsbold;
		font-size: 13px;
		line-height: 15px;
		padding: 5px;
		max-width: 220px;
		min-width: 220px;
		width: 100%;
		margin: 0px;
		height: 50px;
		border: none;
		border-radius: 0px;
		border-left: 1px solid #d1d1d1;
		border-right: 1px solid #d1d1d1;

		.setalt{
			margin-left: 15px;
		}
		.barrinha{
			margin-right: 5px;
		}
	}
	.drop__categoria{
		position: absolute;
		left: 0px !important;
		background-color: #000;
		padding: 0px 5px 5px 5px;
		border-radius: 0px 0px 20px 20px;
		min-width: 730px;
		top: 100%;
		z-index: 1000;

		.grid__categoria{
			width: 100%;
			background-color: #fff;
			border-radius: 20px;
			padding: 0px 25px;
			color: #000;
			display: block;
			position: relative;
			width: calc(calc(352px * 2) + 50px);
			overflow-y: auto;
			height: 550px;
			max-height: 550px;

			.categorias{
				width: 352px;
				height: 550px;
			}

			.dropcateg{
				position: absolute;
				top: 0px;
				opacity: 0;
				width: 352px;
				left: 377px;
				min-height: 550px;
				max-height: 550px;
				height: 550px;
				overflow-y: auto;
				display: block;
			}
		}
		.segura__categoria{


			&:hover{
				z-index: 300;
				.dropcateg{
					opacity: 1;
					z-index: 300;
				}
			}

			button{
				display: none;
			}

			a{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 18px 15px;
				padding-right: 25px;
				font-family: poppinsregular;

				&:hover{
					color: #000;
					background-color: #eaeaea;
					font-family: poppinsbold;
					text-decoration: none;

					&:after{
						opacity: 1 !important;
					}
				}
			}
			.categoria__controls{
				.link__principal{
					&:after{
						width: 6px;
						height: 10px;
						content: '';
						display: block;
						mask-image: url(../images/seta.png);
						mask-repeat: no-repeat;
						mask-size: auto;
						mask-position: center;
						background-color: currentColor;
						opacity: 0.5;
					}
				}
			}
		}
	}
	.main__pesquisa{
		width: 100%;
		max-width: 475px;
		margin-right: 25px;

		@include media-breakpoint-only (lg) {
			max-width: 380px;
		}
	}
	.carrinho__link__topo{
		display: flex;
		align-items: center;
	}
	.topo__main{
		.container{
			display: flex;
			justify-content: space-between;

			.meio__right{
				flex-grow: 1;
			}
		}
		.main__logo{
			margin-right: 30px;
			padding: 20px 0px;
		}
		.topo__meio{
			padding-bottom: 35px;
			.container{
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

	.topo__baixo{
		border-top: 1px solid #d1d1d1;
		border-bottom: 1px solid #d1d1d1;
		.container{
			display: flex;
			position: relative;

		}

		.main__menu{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			flex-grow: 1;

			.dropdown__controls{
				button{
					display: none;
				}
			}
		}
	}
	.categoria__expanded{
		position: absolute;
		left: 15px;
		top: 100%;
		max-width: 240px;
		width: 100%;
		z-index: 600;
		border-radius: 0px;
		box-shadow: 0px 0px 5px rgba(#000, 0.4);
		color: #000000;
		padding: 0px;

		.lista__todas__categorias{
			max-height: calc(100vh - 250px);
			overflow-y: auto;

			&::-webkit-scrollbar{
				width: 10px;
			}
			&::-webkit-scrollbar-thumb{
				background-color: #c61623;
				border-radius: 5px;
			}
		}

		.dropdown__controls{
			position: relative;
			padding-right: 40px;

			.categoria__link{
				&:after{
					opacity: 0;
				}
			}
			&:after {
				width: calc(100% - 40px);
				left: 20px;
				bottom: 0px;
				position: absolute;
				background-color: #d1d1d1;
				content: '';
				height: 1px;
			}
			button{
				position: absolute;
				right: 20px;
				top: calc(50% - 12px);
				background-color: #ffffff;
				color: #fff;
				border: none;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0px;
				font-size: 12px;
				line-height: 12px;
				transition: all 0.3s linear;
				padding: 5px 2px 3px 3px;

				&[aria-expanded="true"]{
					transform: rotate(90deg);
				}
			}
		}

		.dropmenu{
			list-style: none;
			background-color: #c61623;
			color: #fff;
			padding-left: 20px;
			padding-right: 20px;

			li{
				&:last-child{
					a{
						border-bottom: none;
					}
				}
			}

			a{
				display: block;
				padding: 10px;
				border-bottom: 1px solid #fff;
			}
		}

		.categoria__link{
			display: flex;
			justify-content: space-between;
			padding: 20px 10px 20px 10px;
			position: relative;
			font-family: poppinsbold;
			font-size: 14px;
			line-height: 14px;
			width: 100%;

			&:after {
				width: calc(100% - 20px);
				left: 10px;
				bottom: 0px;
				position: absolute;
				background-color: #d1d1d1;
				content: '';
				height: 1px;
			}
		}
	}
	.menu__item{
		position: relative;
		width: 100%;

		.dropdown__controls{
			display: flex;
		}
		.menu__link{
			padding: 10px 2px;
			min-height: 50px;
			position: relative;
			display: flex;
			align-items: center;
			font-size: 13px;
			line-height: 16px;
			justify-content: center;
			text-align: center;
			font-family: poppinssemibold;

			&:hover{
				text-decoration: none;
			}
		}
	}
	.segura__destaques{
		flex-grow: 1;
		position: relative;

		.main__menu{
			.dropmenu{
				position: absolute;
				top: 100% !important;
				left: calc(50% - 128px);
				width: 256px;
				max-height: 300px;
				overflow-y: auto;
				background-color: #c61623;
				list-style: none;
				padding-left: 0px;
				margin-bottom: 0px;
				box-shadow: 0px 0px 8px rgba(#000, 0.4);
				margin: 0px !important;
				z-index: 1000;

				li{
					display: flex;

					&:last-child{
						a{
							border-bottom: none;
						}
					}

					a{
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 15px 10px;
						width: 100%;
						text-align: center;
						color: #fff;
						transition: all 0.3s linear;
						font-family: 'poppinsregular';
						border-bottom: 1px solid rgba(#fff, 40%);
						background-color: #c61623;

						&:hover{
							color: #fff;
							text-decoration: none;
							background-color: #023b20;
						}
					}
				}
			}
		}
	}
		.link__categoria{
			position: relative;
			min-height: 76px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: poppinsbold;
			font-size: 15px;
			line-height: 18px;
			margin: 0px 10px;
			text-align: center;
			text-transform: uppercase;
			color: #fff;

			@include media-breakpoint-only (lg) {
				font-size: 13px;
				line-height: 16px;
				margin: 0px 5px;
			}

			&:hover{
				text-decoration: none;
				color: #fff;

				&:after{
					opacity: 1;
				}
			}

			&:after{
				width: 100%;
				height: 5px;
				position: absolute;
				bottom: 0px;
				left: 0px;
				content: '';
				background-color: currentColor;
				opacity: 0;
			}
		}
	}
	.topo__meio{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.meio__right{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-between;
		max-width: 860px;
	}
	.main__menu{
		display: flex;

		li{
			&:nth-child(1){
				a{
					padding-left: 0px;
				}
			}
		}
	}
	.mobile__icone{
		display: none;
	}
	.topo__mobile{
		display: none;
	}
	.topo__pesquisa{
		display: none;
	}
	.segura__contato__topo{
		display: flex;
		align-items: center;
	}
	.logado__toggler{
		position: relative;
		&:after{
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 10px solid #c61623;
            content: '';
            position: absolute;
            left: 8px;
            bottom: -15px;
            display: none;
			z-index: 100;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
	}
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: -200px !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000000, 0.6);
		transform: unset !important;
		top: calc(100% + 15px) !important;
		border-radius: 0px;
	}
	.topo__cima{
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-bottom: 35px;
		padding-top: 5px;
		align-items: center;
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		z-index: 1080;
		height: 100%;
		overflow-y: auto;
		background-color: #fff;
		transition: all 0.5s linear;
		flex-direction: column;
		display: flex;

		.topo__cima{
			order: 3;
		}
		.topo__meio{
			order: 1;
		}
		.topo__baixo{
			order: 2;

			.menu__categoria{
				display: none;
			}
		}
		.menu__categoria__especial{
			a{
				display: block;
				width: 100%;
				padding: 10px;
				font-family: poppinsregular;
				color: #fff;
				background-color: #c61623;
			}
		}
		.dropcateg{
			a{
				background-color: #000;
				color: #fff;
			}
		}
		.link__categoria__especial{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #000000;
			color: #fff;
			padding: 20px 5px;
			font-family: poppinsbold;
			border: none;
			font-size: 12px;
			line-height: 14px;

			span{
				margin: 0px 5px;
			}
		}
		.categoria__controls{
			position: relative;
			width: 100%;
			display: flex;

			button{
				position: absolute;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
				padding: 0px;
				display: flex;
				align-items:center;
				justify-content: center;
				background-color: #000;
				color: #fff;
				border: none;

				&[aria-expanded="true"]{
					&:after{
						transform: rotate(90deg);
					}
				}

				&:after{
					width: 6px;
					height: 10px;
					content: '';
					display: block;
					mask-image: url(../images/seta.png);
					mask-repeat: no-repeat;
					mask-size: auto;
					mask-position: center;
					background-color: currentColor;
					opacity: 1;
				}
			}
		}
		.main__pesquisa{
			display: none;
		}

		.container{
			padding: 0px;
		}

		&.shown{
			left: 0px;
		}
		.segura__login__topo{
			display: none;
		}
		.segura__carrinho__topo{
			display: none;
		}
	}
	.mbl__logo{
		max-width: 80px;
		width: 100%;
	}
	.main__logo{
		text-align: center;
		padding: 20px;
	}
	.topo__mobile{
		background-color: #fff;
		padding: 10px 0px;
		border-bottom: 2px solid #000;

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		color: #000;
		font-size: 15px;
		line-height: 15px;
		padding: 0px 8px;
	}
	.segura__login__topo{
		.login__icone{
			display: none;
		}
		.login__texto{
			display: none;
		}
		.mobile__icone{
			padding: 0px 8px;
			border-left: 1px solid rgba(#fff, 1);
		}
	}
	.segura__carrinho__topo{
		position: relative;
		.carrinho__texto{
			display: none;
		}
		.carrinho__icone{
			display: none;
		}
		.mobile__icone{
			padding: 0px 5px;
			border-left: 1px solid rgba(#fff, 1);
			position: relative;
		}
	}
	.mbl__left,
	.mbl__right{
		display: flex;
		align-items: center;
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000000, 0.6);
        right: -10px !important;
		left: unset !important;
		top: calc(100% + 10px)!important;
		transform: unset !important;
		border-radius: 0px;
    }
	.logado__toggler{
		&[aria-expanded="true"] {
			&:after{
				display: block;
			}
		}

		&:after{
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 10px solid #000;
			content: '';
			position: absolute;
			right: 7px !important;
			bottom: -10px;
			display: none;
			z-index: 100;
		}
	}
	.main__menu{
		background-color: #fff;

		.dropmenu{
			list-style: none;
			padding: 0px;
			margin: 0px;

			a{
				display: block;
				padding: 10px;
				background-color: #000000;
				color: #fff;
				font-family: 'poppinsregular';
				border-bottom: 1px solid #000000;
			}
		}

		.menu__link{
			padding: 10px 20px 10px 10px;
			display: flex;
			align-items: center;
			font-family: 'poppinsregular';
			border-bottom: 1px solid #fff;
			background-color: #fff;
			color: #000;

			.menu__link__icone{
				margin-right: 5px;
				padding-bottom: 2px;
			}
		}
	}
	.segura__contato__topo{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;

		& > *{
			margin: 10px 0px;
		}

		.barrinhalt{
			display: none;
		}
	}
	.dropdown__controls{
		position: relative;

		button{
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(50% - 10px);
			right: 10px;
			border: none;
			background-color: #000000;
			color: #fff;
			border-radius: 10px;
			padding: 3px 5px 1px 5px;
			font-size: 12px;
			line-height: 12px;
			transition: all 0.3s linear;
			display: flex;
			align-items: center;
			justify-content: center;

			&[aria-expanded="true"]{
				transform: rotate(90deg);
			}
		}
	}
	.main__cima{
		color: #000000;
		text-align: center;
		padding: 10px;

		.contato__topo{
			margin-bottom: 20px;
		}
	}
	.main__menu{
		display: none;
	}
	.segura__sociais__topo{
		display: flex;
		flex-direction: column;

		.sociais__span__topo{
			margin-top: 10px;
			margin-bottom: 3px;
		}
		.contato__sociais{
			justify-content: center;
			margin-bottom: 20px;
		}
	}
	.lista__todas__categorias{
		a{
			display: block;
			padding: 10px;
		}
	}
}
