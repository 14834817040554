
.produto__card{
	display: block;
	max-width: 255px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		text-decoration: none;
		box-shadow: 0px 0px 5px rgba(#000, 0.5);

		.produto__card__imagem{
			.segura__lupa__produto,
			&:after{
				opacity: 1;
			}
		}
	}

	a{
		&:hover{
			text-decoration: none;
		}
	}

	.produto__card__conteudo{
		display: block;
		padding-left: 12px;
		padding-right: 12px;
	}

	.produto__card__imagem{
		position: relative;
		margin-bottom: 18px;
		border-radius: 0px;
		text-align: center;
		border: 1px solid #d1d1d1;

		.segura__lupa__produto{
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: #c61623;
			color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: all 0.3s linear;
			z-index: 10;
		}

		&:after{
			width: 100%;
			height: 100%;
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			background-color: rgba(#000, 0.2);
			opacity: 0;
			transition: all 0.3s linear;
			z-index: 5;
		}

		img{
			border-radius: 0px;
			position: relative;
			z-index: 3;
		}

		.produto__card__tags{
			position: absolute;
			left: 10px;
			top: 10px;
			display: flex;
			z-index: 20;

			.produto__card__tag{
				width: 55px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #c61623;
				color: #fff;
				font-family: 'poppinssemibold';
				font-size: 12px;
				line-height: 14px;
				margin-bottom: 5px;
				border-radius: 10px;
				text-align: center;
				margin: 2px;

				&.novidade__tag{
					background-color: #000;
				}

				.icon{
					margin-right: 5px;
				}
			}
		}
	}
	.produto__stars{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 10px;

		.rating__stars{
			color: rgba(#ffae00, 40%);
			margin-right: 5px;
			i{
				position: relative;
			}
		}
		.rating__stars .fa-star:after {
			  position: absolute;
			color: #ffae00;
			left: 0;
		}
		.rating__stars[data-rating="0.5"] .fas:nth-child(1):after {
			  content: "";
		}

		.rating__stars[data-rating="1.5"] .fas:nth-child(2):after {
			  content: "";
		}

		.rating__stars[data-rating="2.5"] .fas:nth-child(3):after {
			  content: "";
		}

		.rating__stars[data-rating="3.5"] .fas:nth-child(4):after {
			 content: "";
		}

		.rating__stars[data-rating="4.5"] .fas:nth-child(5):after {
			 content: "";
		}

		.rating__stars[data-rating="5.5"] .fas:nth-child(6):after {
			 content: "";
		}

		.rating__stars[data-rating="1"] .fas:nth-child(1),
		.rating__stars[data-rating="1.5"] .fas:nth-child(1) {
			  color: #ffae00;
		}

		.rating__stars[data-rating="2"] .fas:nth-child(1),
		.rating__stars[data-rating="2"] .fas:nth-child(2),
		.rating__stars[data-rating="2.5"] .fas:nth-child(1),
		.rating__stars[data-rating="2.5"] .fas:nth-child(2) {
			  color: #ffae00;
		}

		.rating__stars[data-rating="3"] .fas:nth-child(1),
		.rating__stars[data-rating="3"] .fas:nth-child(2),
		.rating__stars[data-rating="3"] .fas:nth-child(3),
		.rating__stars[data-rating="3.5"] .fas:nth-child(1),
		.rating__stars[data-rating="3.5"] .fas:nth-child(2),
		.rating__stars[data-rating="3.5"] .fas:nth-child(3) {
			  color: #ffae00;
		}

		.rating__stars[data-rating="4"] .fas:nth-child(1),
		.rating__stars[data-rating="4"] .fas:nth-child(2),
		.rating__stars[data-rating="4"] .fas:nth-child(3),
		.rating__stars[data-rating="4"] .fas:nth-child(4),
		.rating__stars[data-rating="4.5"] .fas:nth-child(1),
		.rating__stars[data-rating="4.5"] .fas:nth-child(2),
		.rating__stars[data-rating="4.5"] .fas:nth-child(3),
		.rating__stars[data-rating="4.5"] .fas:nth-child(4) {
			  color: #ffae00;
		}
		.rating__stars[data-rating="5"] {
			  color: #ffae00;
		}
	}
	.produto__card__titulo{
		--linhas: 2;
		font-family: 'poppinssemibold';
		font-size: 14px;
		line-height: 16px;
		min-height: 32px;
		text-align: left;
		margin-bottom: 5px;
		color: #000000;
	}
	.produto__card__valor{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #000000;
		font-size: 16px;
		line-height: 18px;
		font-family: 'poppinsextrabold';
		margin-bottom: 15px;

		.produto__valor__promocao{
			font-size: 13px;
			line-height: 15px;
			margin-right: 5px;
			font-family: poppinsregular;
			color: #999999;

			span{
				text-decoration: line-through;
			}
		}
	}
}
.produto__comprar__btn__card{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	background-color: #000;
	color: #fff;
	font-family: poppinsbold;
	transition: all 0.3s linear;
	border: none;

	&:hover{
		background-color: #c61623;
		color: #fff;
		text-decoration: none;
	}
}
.qualidade__home{
	color: #000;
	padding-top: 50px;
	position: relative;
	padding-bottom: 70px;

	.conteudo__btn{
		min-width: 255px;
		height: 40px;
		font-family: 'poppinsbold';
		font-size: 13px;
		line-height: 15px;
	}

	@include media-breakpoint-down (md) {
		.col-lg-6{
			margin-bottom: 30px;
			text-align: center;
		}
	}

	&:after{
		width: 1920px;
		height: calc(100% + 140px);
		bottom: 0px;
		z-index: -1;
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		background-image: url(../images/bg-qualidade.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	.clearfix{
		margin-bottom: 20px;
	}

	.row{
		align-items: center;
	}
}
.qualidade__titulo{
	font-family: poppinssemibold;
	font-size: 40px;
	line-height: 44px;
	margin-bottom: 15px;
	color: #000;
	max-width: 360px;
	width: 100%;
}
.bannerszinhos{
	padding-top: 80px;
}
.banner__link{
	display: block;
	width: 100%;
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
}
.destaque{
	padding-top: 75px;
}
.produtos__owl.owl-carousel{
	display: flex;
	flex-direction: column;

	.owl-item{
		padding: 5px;
	}

	.owl-stage-outer{
		order: 2;
	}
	.owl-nav{
		order: 1;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 15px;

		button.owl-prev,
		button.owl-next{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border: none;
			border-radius: 50%;
			color: #fff;
			background-color: #000;
			transition: all 0.3s linear;
			padding: 0px;
			margin: 3px;

			&:hover{
				background-color: #c61623;
				color: #fff;
			}
		}
	}
}
.titulo__secao{
	font-family: poppinssemibold;
	font-size: 40px;
	line-height: 44px;
	color: #000;
	display: flex;
	align-items: center;

	&:after{
		flex-grow: 1;
		max-width: 100%;
		display: block;
		height: 1px;
		background-color: #999999;
		content: '';
		margin-left: 20px;
	}
}
.last{
	padding-bottom: 85px;
}
