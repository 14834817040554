
.rodape{
	background-color: #000000;
	color: #fff;

	.rodape__conteudo{
		padding-top: 130px;
	}
}
.rodape__grid{
	display: grid;
}
.rodape__creditos{
	padding: 35px 0px;
	background-color: #c61623;
	color: #fff;
	font-size: 12px;
	line-height: 14px;

	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.gv8__box{
	display: flex;
	align-items: center;

	svg{
		margin-left: 5px;
	}
}
.rodape__contatos{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;

	.colspan{
		grid-column: span 2;
	}
}
.rodape__menu{
	font-size: 13px;
	line-height: 16px;
	font-family: poppinsregular;
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;

	li{
		margin-bottom: 6px;
	}
}
.rodape__contato__titulo{
	font-family: poppinsbold;
	margin-bottom: 5px;
	font-size: 13px;
	line-height: 15px;
}
.rodape__contato__conteudo{
	font-family: poppinsregular;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0px;
	margin-bottom: 22px;
}
.rodape__titulo{
	font-family: poppinssemibold;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;

	@include media-breakpoint-up (lg) {
		padding-top: 90px;
	}
}
.selo__rodape{
	display: flex;
	align-items: center;
	padding: 25px 0px;
	font-family: poppinslight;
	font-size: 16px;
	line-height: 20px;

	.selo__rodape__titulo{
		font-family: poppinsbold;
	}

	.selo__rodape__icone{
		min-width: 40px;
		text-align: center;
		margin-right: 12px;
	}
}
.rodape__grid__selos{
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(#fff, 0.2);
}
.rodape__newsletter{
	padding: 34px 0px;

	.news__grid{
		display: grid;
		align-items: center;
		grid-template-columns: 350px 1fr;
		column-gap: 20px;
	}
	.news__rodape__form{
		display: grid;
		grid-template-columns: 1fr 1fr 122px;
		column-gap: 18px;


	}
}
.rodape__input{
	width: 100%;
	height: 50px;
	display: block;
	background-color: #000;
	color: #fff;
	border: 1px solid #fff;
	padding-left: 20px;
	padding-right: 20px;
	font-family: poppinslight;
	font-size: 13px;
	line-height: 15px;

	&::placeholder{
		color: #fff;
	}
}
.news__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #c61623;
	color: #fff;
	min-height: 50px;
	width: 100%;
	font-family: poppinsbold;
	border: none;
	transition: all 0.3s linear;

	&:hover{
		background-color: darken(#c61623, 10%);
		color: #fff;
		text-decoration: none;
	}
}
.news__texto{
	font-family: poppinssemibold;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 15px;
}
.news__desc{
	font-family: poppinslight;
	font-size: 13px;
	line-height: 17px;
}
@include media-breakpoint-up (lg) {
	.rodape__grid{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(#fff, 0.2);
		padding-bottom: 50px;

		& > *{
			margin-bottom: 26px;
		}

		.col__1{
			max-width: 270px;
			width: 100%;
		}
		.col__2{
			max-width: 270px;
			width: 100%;
		}
		.col__3{
			max-width: 130px;
			width: 100%;
		}
		.col__4{
			max-width: 160px;
			width: 100%;
		}
		.col__5{
			max-width: 200px;
			width: 100%;
		}
	}
}
.rodape__desc{
	font-size: 13px;
	line-height: 17px;
	margin-bottom: 25px;
	font-family: poppinslight;
}
.rodape__logo{
	margin-bottom: 20px;
}
.rodape__sociais{
	display: flex;
	justify-content: flex-start;

	a{
		display: flex;
		align-items: center;
		justify-content:center;
		font-size: 20px;
		width: 37px;
		height: 37px;
		line-height: 20px;
		border: 1px solid #fff;
		color: #fff;
		margin: 6px;
		transition: all 0.3s linear;
		border-radius: 50%;
		&:hover{
			background-color: #c61623;
			color: #fff;
			border-color: #c61623;
			text-decoration: none;
		}
	}
}
@include media-breakpoint-down (md) {
	.rodape__grid,
	.news__grid,
	.news__rodape__form,
	.rodape__grid__selos{
		display: block !important;
		text-align: center;

		.selo__rodape{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 0px;

			.selo__rodape__icone{
				margin-bottom: 10px;
			}
		}
	}
	.rodape__grid,
	.news__grid,
	.news__rodape__form{
		& > *{
			margin-bottom: 15px;
		}
	}
	.rodape__creditos{
		.container{
			flex-direction: column;
		}
	}
	.news__item{
		flex-direction: column;
	}
}
