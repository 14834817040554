// bg-position(x,y) width height
$icons: (
	'barrinha': -84px -59px 20px 15px,
	'caminhao': -54px -84px 18px 13px,
	'carrinho': -84px -31px 18px 18px,
	'email': -115px -25px 14px 12px,
	'face': -42px -42px 31px 32px,
	'html': 0px 0px 32px 32px,
	'insta': -42px 0px 32px 32px,
	'left': -115px -47px 8px 11px,
	'linked': 0px -42px 32px 32px,
	'lupa': 0px -84px 18px 18px,
	'right': -115px -68px 8px 11px,
	'setalt': -84px 0px 21px 21px,
	'tel': -115px 0px 15px 15px,
	'user': -28px -84px 16px 18px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 130px 102px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 130px 102px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;
}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
